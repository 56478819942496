import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import {
  ABOUT_US_PATH,
  COMUNICATION_SERVICE_PATH,
  HOMEPAGE_PATH,
  IT_SERVICE_PATH,
  PRODUCTS_PATH,
} from "../constants/Type";
import { scrollToTop } from "../services/pageService";
import Footbar from "../components/Footbar";
import Navbar from "../components/Navbar";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [isStarting, setIsStarting] = useState<boolean>(true);
  const [isMediumView, setIsMediumView] = useState<boolean>(false);

  const orangeCicle = useRef<HTMLDivElement | null>(null);
  const orangeCicle2 = useRef<HTMLDivElement | null>(null);
  const orangeCicle3 = useRef<HTMLDivElement | null>(null);

  const azureCicle = useRef<HTMLDivElement | null>(null);
  const azureCicle2 = useRef<HTMLDivElement | null>(null);
  const azureCicle3 = useRef<HTMLDivElement | null>(null);

  const purpleCicle = useRef<HTMLDivElement | null>(null);
  const purpleCicle2 = useRef<HTMLDivElement | null>(null);
  const purpleCicle3 = useRef<HTMLDivElement | null>(null);

  const yellowCicle = useRef<HTMLDivElement | null>(null);
  const yellowCicle2 = useRef<HTMLDivElement | null>(null);
  const yellowCicle3 = useRef<HTMLDivElement | null>(null);

  const greenCicle = useRef<HTMLDivElement | null>(null);
  const greenCicle2 = useRef<HTMLDivElement | null>(null);
  const greenCicle3 = useRef<HTMLDivElement | null>(null);

  let animationTimeout: NodeJS.Timeout | null = null; // Definisci una variabile per memorizzare il timeout

  useEffect(() => {
    verifyWidth();
    window.addEventListener("resize", verifyWidth);
    setTimeout(() => {
      setIsStarting(false);
    }, 900);

    animateDiv(orangeCicle);
    animateDiv(orangeCicle2);
    animateDiv(orangeCicle3);

    animateDiv(azureCicle);
    animateDiv(azureCicle2);
    animateDiv(azureCicle3);

    animateDiv(purpleCicle);
    animateDiv(purpleCicle2);
    animateDiv(purpleCicle3);

    animateDiv(yellowCicle);
    animateDiv(yellowCicle2);
    animateDiv(yellowCicle3);

    animateDiv(greenCicle);
    animateDiv(greenCicle2);
    animateDiv(greenCicle3);

    return () => {
      if (animationTimeout) {
        clearTimeout(animationTimeout);
      }
      window.removeEventListener("resize", verifyWidth);
    };
  }, []);

  const verifyWidth = () => {
    if (window.innerWidth < 680 && !isMobile) {
      setIsMediumView(true);
    } else {
      setIsMediumView(false);
    }
  };

  const animateDiv = (cicle: any) => {
    try {
      const newq = makeNewPosition();
      const oldq = cicle.current?.getBoundingClientRect();
      // const speed = calcSpeed([oldq!.top, oldq!.left], newq);
      const distance = calcolaDistanza(oldq!.left, oldq!.top, newq[1], newq[0]);
      const duration = Math.sqrt((2 * distance) / 0.1);

      cicle.current!.style.transition = `top ${duration / 8}s, left ${duration / 8}s`;
      cicle.current!.style.top = newq[0] + "px";
      cicle.current!.style.left = newq[1] + "px";

      animationTimeout = setTimeout(() => animateDiv(cicle), duration * 100);
    } catch (error) { }
  };

  const makeNewPosition = () => {
    const h = 360;
    let w = window.innerWidth - 80;

    if (!isMobile && window.innerWidth > 1000) {
      w = 900;
    }
    const nh = Math.floor(Math.random() * h) + 120;
    let nw = Math.floor(Math.random() * w) + 10;

    if (!isMobile && window.innerWidth > 1000) {
      let num = window.innerWidth / 2 - 490;
      nw = Math.floor(Math.random() * w) + num;
    }

    return [nh, nw];
  };

  // const calcSpeed = (prev: any, next: any) => {
  //   const x = Math.abs(prev[1] - next[1]);
  //   const y = Math.abs(prev[0] - next[0]);

  //   const greatest = x > y ? x : y;

  //   const speedModifier = 0.1;

  //   const speed = Math.ceil(greatest / speedModifier);

  //   return 300;
  //   //return speed;
  // };

  const calcolaDistanza = (x1: number, y1: number, x2: number, y2: number): number => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  const goToCenter = (cicle: any) => {
    try {
      cicle.current!.classList.add("animate__animated");
      cicle.current!.classList.add("animate__tada");
      setTimeout(() => {
        cicle.current!.classList.remove("animate__animated");
        cicle.current!.classList.remove("animate__tada");
      }, 1000);
      const newq = [250, window.innerWidth / 2];
      const oldq = cicle.current?.getBoundingClientRect();
      // const speed = calcSpeed([oldq!.top, oldq!.left], newq);
      const distance = calcolaDistanza(oldq!.left, oldq!.top, newq[1], newq[0]);
      const duration = Math.sqrt((2 * distance) / 0.1);

      cicle.current!.style.transition = `top ${duration / 30}s, left ${duration / 30}s`;
      cicle.current!.style.top = newq[0] + "px";
      cicle.current!.style.left = newq[1] + "px";

      setTimeout(() => animateDiv(cicle), duration * 100);
    } catch (error) { }
  }

  return (
    <div className="col-12 m-0 p-0 pb-5" style={{ minHeight: window.innerHeight }}>
      <Navbar page={HOMEPAGE_PATH}></Navbar>
      <div className={isMobile ? "col-12 m-0 row page-container-mobile px-2" : "col-12 m-0 mx-auto row page-container px-2 max-width-card-section"}>
        <div className="col-12 m-0 row p-0 light-azure logo-box">
          <div className="col-12 m-0 p-0 my-5">
            <div className="logo-homepage"></div>
            <div className="cicle-orange ball-movement pointer" ref={orangeCicle}
              style={{
                top: 160,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 + 300) : (window.innerWidth / 1.5),
              }}
              onClick={() => goToCenter(orangeCicle)}>
            </div>
            <div className="cicle-orange ball-movement pointer" ref={orangeCicle2}
              style={{
                top: 380,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 + 400) : (window.innerWidth / 1.2),
              }}
              onClick={() => goToCenter(orangeCicle2)}>
            </div>
            <div className="cicle-orange ball-movement pointer" ref={orangeCicle3}
              style={{
                top: 124,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 300) : (window.innerWidth / 8),
              }}
              onClick={() => goToCenter(orangeCicle3)}>
            </div>
            <div className="cicle-green ball-movement pointer" ref={greenCicle}
              style={{
                top: 400,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2) : (window.innerWidth / 2),
              }}
              onClick={() => goToCenter(greenCicle)}>
            </div>
            <div className="cicle-green ball-movement pointer" ref={greenCicle2}
              style={{
                top: 210,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 400) : (window.innerWidth / 10),
              }}
              onClick={() => goToCenter(greenCicle2)}>
            </div>
            <div className="cicle-green ball-movement pointer" ref={greenCicle3}
              style={{
                top: 130,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 250) : (window.innerWidth / 1.2),
              }}
              onClick={() => goToCenter(greenCicle3)}>
            </div>
            <div className="cicle-purple ball-movement pointer" ref={purpleCicle}
              style={{
                top: 125,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 150) : (window.innerWidth / 1.3),
              }}
              onClick={() => goToCenter(purpleCicle)}>
            </div>
            <div className="cicle-purple ball-movement pointer" ref={purpleCicle2}
              style={{
                top: 415,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 220) : (window.innerWidth / 1.4),
              }}
              onClick={() => goToCenter(purpleCicle2)}>
            </div>
            <div className="cicle-purple ball-movement pointer" ref={purpleCicle3}
              style={{
                top: 410,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 + 330) : (window.innerWidth / 20),
              }}
              onClick={() => goToCenter(purpleCicle3)}>
            </div>
            <div className="cicle-yellow ball-movement pointer" ref={yellowCicle}
              style={{
                top: 260,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 + 150) : (window.innerWidth / 2.2),
              }}
              onClick={() => goToCenter(yellowCicle)}>
            </div>
            <div className="cicle-yellow ball-movement pointer" ref={yellowCicle2}
              style={{
                top: 350,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 180) : (window.innerWidth / 4),
              }}
              onClick={() => goToCenter(yellowCicle2)}>
            </div>
            <div className="cicle-yellow ball-movement pointer" ref={yellowCicle3}
              style={{
                top: 125,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 100) : (window.innerWidth / 3),
              }}
              onClick={() => goToCenter(yellowCicle3)}>
            </div>
            <div className="cicle-azure ball-movement pointer" ref={azureCicle}
              style={{
                top: 160,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 + 280) : (window.innerWidth / 1.2),
              }}
              onClick={() => goToCenter(azureCicle)}>
            </div>
            <div className="cicle-azure ball-movement pointer" ref={azureCicle2}
              style={{
                top: 130,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2) : (window.innerWidth / 2),
              }}
              onClick={() => goToCenter(azureCicle2)}>
            </div>
            <div className="cicle-azure ball-movement pointer" ref={azureCicle3}
              style={{
                top: 410,
                left: window.innerWidth > 1000 ? (window.innerWidth / 2 - 250) : (window.innerWidth / 3),
              }}
              onClick={() => goToCenter(azureCicle3)}>
            </div>
          </div>
        </div>
      </div>

      {
        isMobile ?
          (<div className="col-12 section p-0 row">
            <div className="col-9 mx-auto col-lg-12 m-0 py-0">
              <span className="tag-label-mobile PoppinsLight">Siamo un' </span>
              <span className="title-card-home-creative-mobile PoppinsMedium">Agenzia Creativa</span>
            </div>
            <div className="col-9 mx-auto col-lg-12 m-0 text-align-left">
              <span className="tag-label-mobile PoppinsLight">specializzata in</span>
            </div>
            <div className="col-9 mx-auto col-lg-6 section-little">
              <div className={isStarting ? "col-12 m-0 p-0 pb-3 click-card panel purple row justify-content-end animate__animated animate__zoomIn" : "col-12 m-0 p-0 pb-3 click-card panel purple row justify-content-end"} onClick={() => {
                scrollToTop();
                navigate(IT_SERVICE_PATH);
              }}>
                <div className="col-12 mt-3" style={{ marginBottom: 80 }}>
                  <div className="icon-it-mobile" style={{ backgroundPosition: "left" }}></div>
                </div>
                <div className="col-12 m-0">
                  <span className="title-card-home-mobile color-white Poppins">Applicazioni</span>
                </div>
                <div className="col-12 m-0">
                  <span className="description-card-home-mobile color-white PoppinsLight">La nostra specialità è la creazione di App che siano all'avanguardia con una UI/UX semplice e intuitiva.</span>
                </div>
              </div>
            </div>
            <div className="col-9 mx-auto col-lg-6 m-0 mt-5">
              <div className={isStarting ? "col-12 m-0 p-0 pb-3 click-card panel green row justify-content-end animate__animated animate__zoomIn" : "col-12 m-0 p-0 pb-3 click-card panel green row justify-content-end"} onClick={() => {
                scrollToTop();
                navigate(PRODUCTS_PATH);
              }}>
                <div className="col-12 mt-3" style={{ marginBottom: 80 }}>
                  <div className="icon-manu-mobile" style={{ backgroundPosition: "left" }}></div>
                </div>
                <div className="col-12 m-0">
                  <span className="title-card-home-mobile color-white Poppins">Prodotti</span>
                </div>
                <div className="col-12 m-0">
                  <span className="description-card-home-mobile color-white PoppinsLight">Il design dei nostri prodotti vogliono trasmettere lo stile che ci identifica dando forma all'idee più creative.</span>
                </div>
              </div>
            </div>
            <div className="col-9 mx-auto col-lg-6 m-0 mt-5">
              <div className={isStarting ? "col-12 m-0 p-0 pb-3 click-card panel yellow row justify-content-end animate__animated animate__zoomIn" : "col-12 m-0 p-0 pb-3 click-card panel yellow row justify-content-end"} onClick={() => {
                scrollToTop();
                navigate(COMUNICATION_SERVICE_PATH);
              }}>
                <div className="col-12 mt-3" style={{ marginBottom: 80 }}>
                  <div className="icon-comm-mobile" style={{ backgroundPosition: "left" }}></div>
                </div>
                <div className="col-12 m-0">
                  <span className="title-card-home-mobile Poppins">Comunicazione</span>
                </div>
                <div className="col-12 m-0">
                  <span className="description-card-home-mobile PoppinsLight">Con inventiva e innovazione, trasformeremo la tua visione in realtà, raggiungendo i tuoi obiettivi.</span>
                </div>
              </div>
            </div>
          </div>)
          :
          (
            <div className="col-12 p-0 row mx-auto section-little max-width-card-section">
              <div className={isMediumView ? "col-12 m-0 py-0 max-width-card-tech mx-auto" : "col-12 m-0 py-0"}>
                <span className="tag-label PoppinsLight">Siamo un' </span>
                {isMediumView && <br /> }
                <span className="title-card-home-creative PoppinsMedium">Agenzia Creativa</span>
              </div>
              <div className={isMediumView ? "col-12 m-0 text-align-right mx-auto max-width-card-tech" : "col-12 m-0 text-align-left"}>
                <span className="tag-label PoppinsLight">specializzata in</span>
              </div>
              <div className={isMediumView ? "col-12 m-0 mt-3 mx-auto max-width-card-tech" : "col-12 col-sm-6 col-md-6 col-lg-4 m-0 mt-3"}>
                <div className={isStarting ? "col-12 m-0 p-0 pb-3 click-card panel purple row justify-content-end animate__animated animate__zoomIn" : "col-12 m-0 p-0 pb-3 click-card panel purple row justify-content-end"} onClick={() => {
                  scrollToTop();
                  navigate(IT_SERVICE_PATH);
                }}>
                  <div className="col-12 mt-2" style={{ marginBottom: 80 }}>
                    <div className="icon-it" style={{ backgroundPosition: "left" }}></div>
                  </div>
                  <div className="col-12 m-0">
                    <span className="title-card-home color-white Poppins">Applicazioni</span>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home color-white PoppinsLight">La nostra specialità è la creazione di App che siano all'avanguardia con una UI/UX semplice e intuitiva.</span>
                  </div>
                </div>
              </div>
              <div className={isMediumView ? "col-12 m-0 mt-3 mx-auto max-width-card-tech" : "col-12 col-sm-6 col-md-6 col-lg-4 m-0 mt-3"}>
                <div className={isStarting ? "col-12 m-0 p-0 pb-3 click-card panel green row justify-content-end animate__animated animate__zoomIn" : "col-12 m-0 p-0 pb-3 click-card panel green row justify-content-end"} onClick={() => {
                  scrollToTop();
                  navigate(PRODUCTS_PATH);
                }}>
                  <div className="col-12 mt-2" style={{ marginBottom: 80 }}>
                    <div className="icon-manu" style={{ backgroundPosition: "left" }}></div>
                  </div>
                  <div className="col-12 m-0">
                    <span className="title-card-home color-white Poppins">Prodotti</span>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home color-white PoppinsLight">Il design dei nostri prodotti vogliono trasmettere lo stile che ci identifica dando forma all'idee più creative.</span>
                  </div>
                </div>
              </div>
              <div className={isMediumView ? "col-12 m-0 mt-3 mx-auto max-width-card-tech" : "col-12 col-sm-6 col-md-6 col-lg-4 m-0 mt-3"}>
                <div className={isStarting ? "col-12 m-0 p-0 pb-3 click-card panel yellow row justify-content-end animate__animated animate__zoomIn" : "col-12 m-0 p-0 pb-3 click-card panel yellow row justify-content-end"} onClick={() => {
                  scrollToTop();
                  navigate(COMUNICATION_SERVICE_PATH);
                }}>
                  <div className="col-12 mt-2" style={{ marginBottom: 80 }}>
                    <div className="icon-comm" style={{ backgroundPosition: "left" }}></div>
                  </div>
                  <div className="col-12 m-0">
                    <span className="title-card-home Poppins">Comunicazione</span>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home PoppinsLight">Con inventiva e innovazione, trasformeremo la tua visione in realtà, raggiungendo i tuoi obiettivi.</span>
                  </div>
                </div>
              </div>
            </div>)
      }

      {/* <div className="col-12 section-little p-0 row max-width-card-section mx-auto">
        <div className={isMediumView  ? "col-12 m-0 mx-auto pb-1 max-width-card-tech" : "col-12 m-0 pb-1"}>
          <span className="tag-label PoppinsLight">Let's read more about us</span>
        </div>
        <div className={isMediumView ? "col-12 m-0 mx-auto pt-0 pb-2 max-width-card-tech" : "col-12 m-0 pt-0 pb-2"}>
          <div className="button-medium redirect-button azure p-0 px-3 pointer" onClick={() => {
            scrollToTop();
            navigate(ABOUT_US_PATH);
          }}>
            <span className="Poppin s button-label">Go to page →</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HomePage;
