import "./App.css";
import "animate.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  ABOUT_US_PATH,
  COMUNICATION_SERVICE_PATH,
  HOMEPAGE_PATH,
  IT_SERVICE_PATH,
  PRODUCTS_PATH,
} from "./constants/Type";
import HomePage from "./pages/HomePage";
import ProductsPage from "./pages/ProductsPage";
import ITServicesPage from "./pages/ITServicesPage";
import CommunicationServicesPage from "./pages/CommunicationServicesPage";
import AboutUsPage from "./pages/AboutUsPage";
import Footbar from "./components/Footbar";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<><ITServicesPage></ITServicesPage> <Footbar page={IT_SERVICE_PATH}></Footbar></>}
          path={IT_SERVICE_PATH}
        ></Route>
        <Route
          element={<><ProductsPage></ProductsPage> <Footbar page={PRODUCTS_PATH}></Footbar></>}
          path={PRODUCTS_PATH}
        ></Route>
        <Route
          element={<><CommunicationServicesPage></CommunicationServicesPage> <Footbar page={COMUNICATION_SERVICE_PATH}></Footbar></>}
          path={COMUNICATION_SERVICE_PATH}
        ></Route>
        <Route
          element={<><AboutUsPage></AboutUsPage> <Footbar page={ABOUT_US_PATH}></Footbar></>}
          path={ABOUT_US_PATH}
        ></Route>
        <Route element={<><HomePage></HomePage> <Footbar page={HOMEPAGE_PATH}></Footbar></>} path={HOMEPAGE_PATH}></Route>
        <Route
          path="*"
          element={<Navigate to={HOMEPAGE_PATH} replace />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
