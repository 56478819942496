import { useState } from "react";
import { isMobile } from "react-device-detect";
import productsVideo from "../assets/videos/products-page.mp4";
import Footbar from "../components/Footbar";
import Navbar from "../components/Navbar";
import { PRODUCTS_PATH } from "../constants/Type";
import { Button, TextField, ThemeProvider, createTheme } from "@mui/material";
import validator from "validator";

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#000000", // Cambia il colore della label
          "&.Mui-focused": {
            color: "#000000", // Cambia il colore della label quando il campo è in focus
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#40b551", // Cambia il colore dell'outline quando il campo è in focus
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Impedisce la trasformazione del testo in maiuscolo
          "&:hover": {
            backgroundColor: "rgba(64, 181, 81, 0.9)", // Cambia il colore di sfondo quando il pulsante è in hover
          },
        },
        containedPrimary: {
          backgroundColor: "#40b551", // Cambia il colore di sfondo quando il pulsante è in stile "contained" e "primary"
        },
      },
    },
  },
});

const ProductsPage: React.FC = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [isShopClosed, setIsShopClosed] = useState<boolean>(true);
  const [productClicked, setProductClicked] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [buttonHover, setButtonHover] = useState<string>("");

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleClickProduct = (product: string) => {
    if (product !== productClicked) {
      setProductClicked(product);
    } else redirectInsta();
  };

  const redirectInsta = () => {
    window.open(
      "https://www.instagram.com/sold.official?igsh=YWdmaXQxb3AyaXZq",
      "_blank",
      "noopener,noreferrer"
    );
  };

  // const saveEmail = () => {
  //   console.log(email)
  // }

  // const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmail(event.target.value);
  //   setIsEmailValid(validator.isEmail(event.target.value));
  //   console.log(validator.isEmail(event.target.value))
  // };

  return (
    <ThemeProvider theme={theme}>
      <div className="col-12 m-0 p-0" style={{ minHeight: window.innerHeight }}>
        <Navbar page={PRODUCTS_PATH}></Navbar>
        <div
          className={
            isShopClosed
              ? "col-12 m-0 p-0 text-align-center"
              : "col-12 m-0 p-0 text-align-center pb-5"
          }
        >
          <div className="col-12 m-0 p-0 relative">
            {isVideoLoaded && (
              <>
                <span className="title-page product-title-page Poppins">
                  hand made ideas
                </span>
                {/* <span className="title-page product-title-page Poppins">{isShopClosed ? "" : "Products"}</span> */}
              </>
            )}
            <video
              className="productsVideo"
              autoPlay
              loop
              muted
              onLoadedData={handleVideoLoad}
            >
              <source src={productsVideo} type="video/mp4" />
            </video>
            <div className="absolute overlay-text">
              <span className="title-homepage"></span>
            </div>
          </div>

          {isVideoLoaded && (
            <div className="col-12 m-0 mt-4 p-0 pb-5 row">
              <span className="tag-label PoppinsLight title-page text-align-center">
                Trasformiamo le idee anche in oggetti concreti! pezzi di design
                unici e senza eguali
              </span>
            </div>
          )}

          {isVideoLoaded && !isShopClosed && (
            <div className="col-12 row m-0 px-1">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 row m-0 p-1">
                <div
                  className="col-12 img-prod img-stayin-alive pointer text-align-left"
                  style={{ position: "relative" }}
                >
                  <span className="title-prod-card color-white Poppins">
                    Stayin'<br></br>Alive
                  </span>
                  <span className="subtitle-prod-card color-white PoppinsLight">
                    T-shirt
                  </span>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 row m-0 p-1">
                <div
                  className="col-12 img-prod img-plate pointer text-align-left"
                  style={{ position: "relative" }}
                >
                  <span className="title-prod-card color-white Poppins">
                    Puzzle<br></br>plate
                  </span>
                  <span className="subtitle-prod-card color-white PoppinsLight">
                    More than a plate
                  </span>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 row m-0 p-1 text-align-left">
                <div
                  className="col-12 m-0 hidden-panel pointer p-3"
                  style={{ position: "relative" }}
                  onClick={redirectInsta}
                >
                  <span className="title-prod-card-more col-12 m-0 Poppins">
                    Text us<br></br>on ig
                  </span>
                  <span className="subtitle-prod-card-more PoppinsLight">
                    Click here :
                  </span>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 row m-0 p-1">
                <div
                  className="col-12 img-prod img-cups pointer text-align-left"
                  style={{ position: "relative" }}
                >
                  <span className="title-prod-card color-white Poppins">
                    Funny<br></br>cups
                  </span>
                  <span className="subtitle-prod-card color-white PoppinsLight">
                    Am I puzzle addicted?
                  </span>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 row m-0 p-1">
                <div
                  className="col-12 img-prod img-bag pointer text-align-left"
                  style={{ position: "relative" }}
                >
                  <span className="title-prod-card color-white Poppins">
                    Regoli<br></br>bag
                  </span>
                  <span className="subtitle-prod-card color-white PoppinsLight">
                    Wtf?
                  </span>
                </div>
              </div>
            </div>
          )}

          {isVideoLoaded && isShopClosed && (
            <div className="col-12 m-0 mt-4 p-0 pb-5 row">
              <div className="col-6 m-0 p-0 text-align-right">
                <span className="tag-label PoppinsLight">
                  Al momento non ci sono prodotti disponibili.
                </span>
              </div>
              <div className="col-6 m-0 p-0"></div>
              <div className="col-6 m-0 p-0 text-align-right">
                <span className="tag-label PoppinsLight">
                  <span className="tag-label PoppinsLight oblique">
                    Seguici
                  </span>{" "}
                  per le novità
                </span>
              </div>
              <div className="col-6 row m-0 p-0 text-align-left align-self-end input-email-div">
                {/* <div className="col-12 m-0">
                  <TextField
                    label="Just your email"
                    style={{ width: '100%', maxWidth: 300 }}
                    onChange={handleChangeEmail}
                    variant="outlined"
                  />
                </div>
                <div className="col-12 mt-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveEmail}
                    disabled={!isEmailValid}>Save</Button>
                </div> */}
                <div className="m-0 mb-2 page-card-padding-both insta-button-div">
                  <div
                    className="col-12 contact-button relative pointer border-green"
                    onMouseEnter={() => setButtonHover("insta")}
                    onMouseLeave={() => {
                      setButtonHover("");
                    }}
                    onClick={redirectInsta}
                  >
                    <span
                      className={
                        buttonHover === "insta"
                          ? "footer-span Poppins email-transition-in"
                          : "footer-span Poppins email-transition-in move-in"
                      }
                    >
                      @sold.official
                    </span>
                    <span
                      className={
                        buttonHover === "insta"
                          ? "footer-span Poppins email-transition-out"
                          : "footer-span Poppins email-transition-out move-out"
                      }
                    >
                      Vai su ig
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ProductsPage;
