import { isMobile } from "react-device-detect";
import Navbar from "../components/Navbar";
import { COMUNICATION_SERVICE_PATH } from "../constants/Type";

const CommunicationServicesPage: React.FC = () => {
  return (
    <div className="col-12 m-0 p-0 pb-5" style={{ minHeight: window.innerHeight }}>
      <Navbar page={COMUNICATION_SERVICE_PATH}></Navbar>
      <div
        className={
          isMobile ? "col-12 row m-0 page-container-mobile" : "col-12 row m-0 page-container justify-content-end"
        }
      >
        <div className="col-12 col-md-6 m-0 py-0">
          <span className="title-card-home-creative PoppinsMedium">Comunicazione</span>
        </div>
        <div className="col-8 col-md-6 m-0 mt-3 py-0">
          <span className="description-card-home PoppinsLight">Abbiamo il pollice verde del mondo digitale! Siamo qui per far brillare la tua voce online. Raccontaci la tua storia e noi la trasformeremo in messaggi che fanno scintille sui social, nei blog e ovunque tu desideri! Sii te stesso, siamo qui per far sì che tutti ti ascoltino!</span>
        </div>
      </div>
    </div>
  );
};

export default CommunicationServicesPage;
