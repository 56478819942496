import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import {
  ABOUT_US_PATH,
  COMUNICATION_SERVICE_PATH,
  HOMEPAGE_PATH,
  IT_SERVICE_PATH,
  PRODUCTS_PATH,
} from "../constants/Type";
import { scrollToTop } from "../services/pageService";

const Navbar: React.FC<{ page: string }> = ({ page }) => {
  const navigate = useNavigate();

  return (
    <div className="col-12 m-0 navbar-div p-0">
      <div className="col-12 container-fluid m-0 px-2 pt-2 p-0">
        {isMobile ? (<div className="row col-12 m-0 p-0 nav-panel-mobile">
          <div
            className={page === HOMEPAGE_PATH ? "col navbar-logo-size-mobile my-auto mx-2 home-selected-mobile light-grey" : "col navbar-logo-size-mobile my-auto mx-2"}
          >
            <div
              className="navbar-logo-img-mobile"
              onClick={() => {
                scrollToTop();
                navigate(HOMEPAGE_PATH);
              }}
            ></div>
          </div>
          <div className="col row p-1 m-0 justify-content-end">
            <div
              className="col  icon-navbar-size-mobile m-1" style={{ position: "relative" }}
            >
              <div className={page === IT_SERVICE_PATH ? "col-12 m-0 p-0 px-2 nav-tech-selected-mobile product-title-page purple normal" : "col-12 m-0 p-0 px-2 nav-tech-mobile product-title-page pointer"} onClick={() => {
                scrollToTop();
                navigate(IT_SERVICE_PATH);
              }}>
                <span className={page === IT_SERVICE_PATH ? "nav-title-mobile color-white" : "nav-title-mobile "}>App</span>
              </div>
            </div>
            <div
              className="col  icon-navbar-size-mobile m-1" style={{ position: "relative" }}
            >
              <div className={page === PRODUCTS_PATH ? "col-12 m-0 p-0 px-2 nav-prod-selected-mobile product-title-page green normal" : "col-12 m-0 p-0 px-2 nav-prod-mobile product-title-page pointer"} onClick={() => {
                scrollToTop();
                navigate(PRODUCTS_PATH);
              }}>
                <span className={page === PRODUCTS_PATH ? "nav-title-mobile color-white" : "nav-title-mobile "}>Prod</span>
              </div>
            </div>

            <div
              className="col  icon-navbar-size-mobile m-1" style={{ position: "relative" }}
            >
              <div className={page === COMUNICATION_SERVICE_PATH ? "col-12 m-0 p-0 px-2 nav-comm-selected-mobile product-title-page yellow normal" : "col-12 m-0 p-0 px-2 nav-comm-mobile product-title-page pointer"} onClick={() => {
                scrollToTop();
                navigate(COMUNICATION_SERVICE_PATH);
              }}>
                <span className="nav-title-mobile ">Comm</span>
              </div>
            </div>
            {/* <div className="col  icon-navbar-size-mobile m-1" style={{ position: "relative" }}>
              <div className={page === ABOUT_US_PATH ? "col-12 m-0 p-0 px-2 nav-us-selected-mobile product-title-page azure normal" : "col-12 m-0 p-0 px-2 nav-us-mobile product-title-page pointer"} onClick={() => {
                scrollToTop();
                navigate(ABOUT_US_PATH);
              }}>
                <span className={page === ABOUT_US_PATH ? "nav-title-mobile color-white" : "nav-title-mobile"}>Us</span>
              </div>
            </div> */}
          </div>
        </div>) : (
          <div className="row col-12 m-0 nav-panel">
            <div
              className={page === HOMEPAGE_PATH ? "col navbar-logo-size p-0 px-2 my-auto mx-0 nav-logo-no-border home-selected-mobile light-grey" : "col navbar-logo-size p-0 px-2 m-auto nav-logo pointer"}
            >
              <div
                className="navbar-logo-img p-0 m-0"
                onClick={() => {
                  scrollToTop();
                  navigate(HOMEPAGE_PATH);
                }}
              ></div>
            </div>
            <div className="col row p-0 py-1 m-0 justify-content-end">
              <div
                className="col  icon-navbar-size m-1" style={{ position: "relative" }}
              >
                <div className={page === IT_SERVICE_PATH ? "col-12 m-0 p-0 px-2 nav-tech-selected product-title-page purple normal" : "col-12 m-0 p-0 px-2 nav-tech product-title-page pointer"} onClick={() => {
                  scrollToTop();
                  navigate(IT_SERVICE_PATH);
                }}>
                  <span className={page === IT_SERVICE_PATH ? "nav-title color-white" : "nav-title "}>App</span>
                </div>
              </div>
              <div
                className="col  icon-navbar-size m-1" style={{ position: "relative" }}
              >
                <div className={page === PRODUCTS_PATH ? "col-12 m-0 p-0 px-2 nav-prod-selected product-title-page green normal" : "col-12 m-0 p-0 px-2 nav-prod product-title-page pointer"} onClick={() => {
                  scrollToTop();
                  navigate(PRODUCTS_PATH);
                }}>
                  <span className={page === PRODUCTS_PATH ? "nav-title color-white" : "nav-title "}>Prod</span>
                </div>
              </div>

              <div
                className="col  icon-navbar-size m-1" style={{ position: "relative" }}
              >
                <div className={page === COMUNICATION_SERVICE_PATH ? "col-12 m-0 p-0 px-2 nav-comm-selected product-title-page yellow normal" : "col-12 m-0 p-0 px-2 nav-comm product-title-page pointer"} onClick={() => {
                  scrollToTop();
                  navigate(COMUNICATION_SERVICE_PATH);
                }}>
                  <span className="nav-title ">Comm</span>
                </div>
              </div>

              {/* <div
                className="col  icon-navbar-size m-1" style={{ position: "relative" }}
              >
                <div className={page === ABOUT_US_PATH ? "col-12 m-0 p-0 px-2 nav-us-selected product-title-page azure normal" : "col-12 m-0 p-0 px-2 nav-us product-title-page pointer"} onClick={() => {
                  scrollToTop();
                  navigate(ABOUT_US_PATH);
                }}>
                  <span className={page === ABOUT_US_PATH ? "nav-title color-white" : "nav-title "}>Us</span>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default Navbar;
