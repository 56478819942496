import { useEffect, useState } from "react";
import { PRODUCTS_PATH } from "../constants/Type";
import { isMobile } from "react-device-detect";

const Footbar: React.FC<{ page: string }> = ({ page }) => {
  const [buttonHover, setButtonHover] = useState<string>("");
  const [isInfoCopied, setInfoCopied] = useState<string>("");
  const [isLittleView, setIsLittleView] = useState<boolean>(false);

  useEffect(() => {
    verifyWidth();
    window.addEventListener("resize", verifyWidth);
    return () => {
      window.removeEventListener("resize", verifyWidth);
    };
  }, []);

  const verifyWidth = () => {
    if (window.innerWidth < 750) {
      setIsLittleView(true);
    } else {
      setIsLittleView(false);
    }
  };
  const copyInfoToClipboard = (type: string) => {
    let info = "";
    if (type === "mail") {
      info = "sold.online.official@gmail.com";
    } else if (type === "phone") {
      info = "+39 3924162864";
    }
    navigator.clipboard
      .writeText(info)
      .then(() => {
        setInfoCopied(type);
      })
      .catch((error) => {
        console.error("Error ", error);
      });
  };

  const redirectInsta = () => {
    window.open(
      "https://www.instagram.com/sold.official?igsh=YWdmaXQxb3AyaXZq",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div>
      <hr className="hr-homepage" />
      <div className="col-12 row p-3  footer-margin footer">
        {isMobile || isLittleView? (
          <>
            <div className="col-12 row p-0">
              <div
                className="col-7  m-2 p-0  contact-button relative pointer border-light-grey"
                onMouseEnter={() => setButtonHover("mail")}
                onMouseLeave={() => {
                  setButtonHover("");
                  setInfoCopied("");
                }}
                onClick={() => copyInfoToClipboard("mail")}
              >
                <span
                  className={
                    buttonHover === "mail"
                      ? "footer-span Poppins email-transition-in"
                      : "footer-span Poppins email-transition-in move-in"
                  }
                >
                  sold.online.official@gmail.com
                </span>
                <span
                  className={
                    buttonHover === "mail"
                      ? "footer-span Poppins email-transition-out"
                      : "footer-span Poppins email-transition-out move-out"
                  }
                >
                  {isInfoCopied === "mail" ? "Copiato :)" : "Copia"}
                </span>
              </div>
            </div>

            <div className="col-12 row p-0">
              <div
                className="col-4  m-2 p-0  contact-button relative pointer border-green"
                onMouseEnter={() => setButtonHover("phone")}
                onMouseLeave={() => {
                  setButtonHover("");
                  setInfoCopied("");
                }}
                onClick={() => copyInfoToClipboard("phone")}
              >
                <span
                  className={
                    buttonHover === "phone"
                      ? "footer-span Poppins email-transition-in"
                      : "footer-span Poppins email-transition-in move-in"
                  }
                >
                  +39 3924162864
                </span>
                <span
                  className={
                    buttonHover === "phone"
                      ? "footer-span Poppins email-transition-out"
                      : "footer-span Poppins email-transition-out move-out"
                  }
                >
                  {isInfoCopied === "phone" ? "Copiato :)" : "Copia"}
                </span>
              </div>
            </div>

            <div className="col-12 row p-0">
              <div
                className="col-4 m-2 p-0 contact-button relative pointer border-yellow"
                onMouseEnter={() => setButtonHover("insta")}
                onMouseLeave={() => {
                  setButtonHover("");
                  setInfoCopied("");
                }}
                onClick={redirectInsta}
              >
                <span
                  className={
                    buttonHover === "insta"
                      ? "footer-span Poppins email-transition-in"
                      : "footer-span Poppins email-transition-in move-in"
                  }
                >
                  @sold.official
                </span>
                <span
                  className={
                    buttonHover === "insta"
                      ? "footer-span Poppins email-transition-out"
                      : "footer-span Poppins email-transition-out move-out"
                  }
                >
                  Go to
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-12 row p-0">
              <div
                className="col-2  m-2 p-0  contact-button relative pointer border-light-grey"
                onMouseEnter={() => setButtonHover("mail")}
                onMouseLeave={() => {
                  setButtonHover("");
                  setInfoCopied("");
                }}
                onClick={() => copyInfoToClipboard("mail")}
              >
                <span
                  className={
                    buttonHover === "mail"
                      ? "footer-span Poppins email-transition-in"
                      : "footer-span Poppins email-transition-in move-in"
                  }
                >
                  sold.online.official@gmail.com
                </span>
                <span
                  className={
                    buttonHover === "mail"
                      ? "footer-span Poppins email-transition-out"
                      : "footer-span Poppins email-transition-out move-out"
                  }
                >
                  {isInfoCopied === "mail" ? "Copiato :)" : "Copia"}
                </span>
              </div>
            </div>

            <div className="col-12 row p-0">
              <div
                className="col-1  m-2 p-0  contact-button relative pointer border-green"
                onMouseEnter={() => setButtonHover("phone")}
                onMouseLeave={() => {
                  setButtonHover("");
                  setInfoCopied("");
                }}
                onClick={() => copyInfoToClipboard("phone")}
              >
                <span
                  className={
                    buttonHover === "phone"
                      ? "footer-span Poppins email-transition-in"
                      : "footer-span Poppins email-transition-in move-in"
                  }
                >
                  +39 3924162864
                </span>
                <span
                  className={
                    buttonHover === "phone"
                      ? "footer-span Poppins email-transition-out"
                      : "footer-span Poppins email-transition-out move-out"
                  }
                >
                  {isInfoCopied === "phone" ? "Copiato :)" : "Copia"}
                </span>
              </div>
            </div>

            <div className="col-12 row p-0">
              <div
                className="col-1 m-2 p-0 contact-button relative pointer border-yellow"
                onMouseEnter={() => setButtonHover("insta")}
                onMouseLeave={() => {
                  setButtonHover("");
                  setInfoCopied("");
                }}
                onClick={redirectInsta}
              >
                <span
                  className={
                    buttonHover === "insta"
                      ? "footer-span Poppins email-transition-in"
                      : "footer-span Poppins email-transition-in move-in"
                  }
                >
                  @sold.official
                </span>
                <span
                  className={
                    buttonHover === "insta"
                      ? "footer-span Poppins email-transition-out"
                      : "footer-span Poppins email-transition-out move-out"
                  }
                >
                  Go to
                </span>
              </div>
            </div>
          </>
        )}

        <div className="col-12 row p-1 ">
          <div className="col-6 row p-0 m-1 text-align-left">
            <span className="footer-span Poppins">© S.O.L.D. 2024</span>
          </div>
          <div className="col-6 row p-0 text-align-right">
            <span className="footer-span Poppins">P.IVA 04973400239</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footbar;
