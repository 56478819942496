import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { ABOUT_US_PATH } from "../constants/Type";
import Footbar from "../components/Footbar";
import Navbar from "../components/Navbar";

const AboutUsPage: React.FC = () => {
  const [isReady, setIsReady] = useState<boolean>(true);
  const [isIpadView, setIsIpadView] = useState<boolean>(false);
  const [isLittleView, setIsLittleView] = useState<boolean>(false);
  useEffect(() => {
    verifyWidth();
    window.addEventListener("resize", verifyWidth);
    return () => {
      window.removeEventListener("resize", verifyWidth);
    };
  }, []);

  const verifyWidth = () => {
    if (window.innerWidth < 1200) {
      setIsIpadView(true);
    } else {
      setIsIpadView(false);
    }
    if (window.innerWidth < 740) {
      setIsLittleView(true);
    } else {
      setIsLittleView(false);
    }
  };

  return (
    <div
      className="col-12 m-0 p-0 pb-5"
      style={{ minHeight: window.innerHeight }}
    >
      <Navbar page={ABOUT_US_PATH}></Navbar>
      {isReady && (
        <div
          className={
            isMobile
              ? "col-12 row m-0 page-container-mobile justify-content-start"
              : "col-12 row m-0 page-container justify-content-start"
          }
        >
          {isIpadView ? (
            <>
              <div className="row m-0 mt-5 py-0 justify-content-center about-us-1">
                <div
                  className="  relative center-column-size"
                  style={{ backgroundColor: "red" }}
                >
                  <div
                    className="fakeimg absolute"
                    style={{ top: 20, left: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 450, right: 50 }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 250,
                      left: 250,
                      backgroundColor: "purple",
                    }}
                  ></div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 20, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 580, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>
              </div>

              <div className="col-12 row m-0 mt-5 py-0 justify-content-center about-us-2">
                <div
                  className="  relative center-column-size"
                  style={{ backgroundColor: "blue" }}
                >
                  <div
                    className="fakeimg absolute"
                    style={{ top: 20, left: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 450, right: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 820, left: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 1250, right: 50 }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 250,
                      left: 250,
                      backgroundColor: "purple",
                    }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 1050,
                      left: 250,
                      backgroundColor: "purple",
                    }}
                  ></div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 20, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 580, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 820, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 1380, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>
              </div>

              <div className="col-12 row m-0 mt-5 py-0 justify-content-center about-us-3">
                <div
                  className="  relative center-column-size"
                  style={{ backgroundColor: "yellow" }}
                >
                  <div
                    className="fakeimg absolute"
                    style={{ top: 20, left: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 450, right: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 820, left: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 1250, right: 50 }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 250,
                      left: 250,
                      backgroundColor: "purple",
                    }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 1050,
                      left: 250,
                      backgroundColor: "purple",
                    }}
                  ></div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 20, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 580, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 820, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 1380, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row m-0 mt-5 py-0 justify-content-center about-us-1">
                <div className=" col relative ">
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 20, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>

                <div
                  className="  relative center-column-size"
                  style={{ backgroundColor: "red" }}
                >
                  <div
                    className="fakeimg absolute"
                    style={{ top: 20, left: 50 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 200, right: 30 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 250,
                      left: 110,
                      backgroundColor: "purple",
                    }}
                  ></div>{" "}
                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 450,
                      left: 340,
                      backgroundColor: "purple",
                    }}
                  ></div>
                </div>

                <div className=" col relative">
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 200, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>
              </div>

              <div className="col-12 row m-0 mt-5 py-0 justify-content-center about-us-2">
                <div className="col relative">
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 30, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 600, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>

                <div
                  className=" col-12 relative center-column-size "
                  style={{ backgroundColor: "blue" }}
                >
                  <div
                    className="fakeimg absolute"
                    style={{ top: 30, left: 20 }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{ top: 200, right: 20 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 600, right: 210 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 1100, left: 210 }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 270,
                      left: 90,
                      backgroundColor: "purple",
                    }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 20,
                      right: 130,
                      backgroundColor: "purple",
                    }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 750,
                      left: 40,
                      backgroundColor: "purple",
                    }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 890,
                      right: 40,
                      backgroundColor: "purple",
                    }}
                  ></div>
                </div>

                <div className="col relative">
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 200, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>

                  <div
                    className="absolute about-us-comment"
                    style={{ top: 1100, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>
              </div>

              <div className="col-12 row m-0 mt-5 py-0 justify-content-center about-us-3">
                <div className="col relative">
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 20, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 650, left: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>

                <div
                  className=" col-12 relative center-column-size "
                  style={{ backgroundColor: "yellow" }}
                >
                  <div
                    className="fakeimg absolute"
                    style={{ top: 20, left: 220 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 300, right: 150 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 650, right: 210 }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{ top: 1150, left: 210 }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 250,
                      left: 90,
                      backgroundColor: "purple",
                    }}
                  ></div>

                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 800,
                      left: 40,
                      backgroundColor: "purple",
                    }}
                  ></div>
                  <div
                    className="fakeimg absolute"
                    style={{
                      top: 920,
                      right: 40,
                      backgroundColor: "purple",
                    }}
                  ></div>
                </div>

                <div className=" col relative">
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 300, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                  <div
                    className="absolute about-us-comment"
                    style={{ top: 1150, right: 20 }}
                  >
                    <div className="about-us-comment-icon"></div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sit corporis error facilis voluptatibus porro laborum
                    nostrum quidem? Magni totam suscipit aliquam. Modi aut
                    libero, nesciunt culpa nisi cupiditate dignissimos ipsam.
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AboutUsPage;
