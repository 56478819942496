import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Footbar from "../components/Footbar";
import Navbar from "../components/Navbar";
import video from "../assets/videos/app-video.mp4";
import creativeVideo from "../assets/videos/creative-path.mp4";
import { IT_SERVICE_PATH } from "../constants/Type";

const ITServicesPage: React.FC = () => {
  const [isLittleView, setIsLittleView] = useState<boolean>(false);
  const [buttonHover, setButtonHover] = useState<string>("");
  const [isInfoCopied, setInfoCopied] = useState<string>("");

  useEffect(() => {
    verifyWidth();
    window.addEventListener("resize", verifyWidth);
    return () => {
      window.removeEventListener("resize", verifyWidth);
    };
  }, []);

  const verifyWidth = () => {
    if (window.innerWidth < 750) {
      setIsLittleView(true);
    } else {
      setIsLittleView(false);
    }
  };

  const copyInfoToClipboard = (type: string) => {
    let info = "";
    if (type === "mail") {
      info = "sold.online.official@gmail.com";
    } else if (type === "phone") {
      info = "+39 3924162864";
    }
    navigator.clipboard
      .writeText(info)
      .then(() => {
        setInfoCopied(type);
      })
      .catch((error) => {
        console.error("Error ", error);
      });
  };

  const redirectInsta = () => {
    window.open(
      "https://www.instagram.com/sold.official?igsh=YWdmaXQxb3AyaXZq",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div
      className="col-12 m-0 p-0 pb-5"
      style={{ minHeight: window.innerHeight }}
    >
      <Navbar page={IT_SERVICE_PATH}></Navbar>
      <div
        className={
          isMobile
            ? "col-12 row m-0 page-container-mobile justify-content-start"
            : "col-12 row m-0 page-container justify-content-start"
        }
      >
        <div className="col-12 m-0">
          <div className="col-12 mt-2 m-0 page-card-padding row">
            <div
              className={
                !isMobile
                  ? "col-12 m-0 p-0 max-width-card-section mx-auto"
                  : "col-12 m-0 p-0 mx-auto"
              }
            >
              <span className="tag-label PoppinsLight">La tua </span>
              <span className="title-card-home-creative PoppinsMedium">
                idea{" "}
              </span>
              {isLittleView || (isMobile && <br></br>)}
              <span className="tag-label PoppinsLight">può essere un </span>
              <span className="title-card-home-creative PoppinsMedium">
                app
              </span>
            </div>
          </div>
        </div>
        <div
          className={
            !isMobile
              ? "col-12 mt-4 m-0 max-width-card-section mx-auto"
              : "col-12 mt-4 m-0 mx-auto"
          }
        >
          <video className="app-video" autoPlay loop muted>
            <source src={video} type="video/mp4" />
          </video>
        </div>

        <div className="col-12 row section py-0 text-align-center justify-content-center">
          <div className="m-0 tag-card-div p-0 normal">
            <div className="tag-card px-3 p-0 tag-item purple">
              <span className="tag-label Poppins color-white center-tag-span">
                Percorso creativo
              </span>
            </div>
          </div>
        </div>

        {!isLittleView ? (
          <>
            <div className="relative col-12 m-0 p-0">
              <video
                className={
                  isMobile
                    ? "path-video p-0 mx-auto mt-4"
                    : "path-video p-0 mt-4 max-width-card-section mx-auto"
                }
                autoPlay
                loop
                muted
              >
                <source src={creativeVideo} type="video/mp4" />
              </video>
            </div>
            <div className="col-12 row m-0 mt-5 py-0 justify-content-center">
              <div className="icon-tech-analysis relative">
                <div className="cicle-icon white"></div>
              </div>
              <div className="col max-width-card-tech p-0 m-0">
                <div
                  className={
                    "col-12 m-0 px-2 py-3 panel row justify-content-end animate__animated animate__zoomIn white shadow"
                  }
                >
                  <div className="col-12 m-0">
                    <span className="title-card-home Poppins">Analisi</span>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home PoppinsLight">
                      Studiamo attentamente le tue necessità e il mercato per
                      trovare la strada migliore per realizzare il tuo progetto
                      con successo.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 row m-0 mt-4 py-0 justify-content-center">
              <div className="col max-width-card-tech p-0 m-0">
                <div
                  className={
                    "col-12 m-0 px-2 py-3 panel row justify-content-end animate__animated animate__zoomIn white shadow"
                  }
                >
                  <div className="col-12 m-0">
                    <span className="title-card-home Poppins">Sviluppo</span>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home PoppinsLight">
                      Ecco dove trasformiamo le idee in azione, dando vita al
                      tuo progetto passo dopo passo.
                    </span>
                  </div>
                </div>
              </div>
              <div className="icon-tech-build relative">
                <div className="cicle-icon white"></div>
              </div>
            </div>
            <div className="col-12 row m-0 mt-4 py-0 justify-content-center">
              <div className="icon-tech-maintenance relative">
                <div className="cicle-icon white"></div>
              </div>
              <div className="col max-width-card-tech p-0 m-0">
                <div
                  className={
                    "col-12 m-0 px-2 py-3 panel row justify-content-end animate__animated animate__zoomIn white shadow"
                  }
                >
                  <div className="col-12 m-0">
                    <span className="title-card-home Poppins">
                      Mantenimento
                    </span>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home PoppinsLight">
                      Una volta completato il progetto, ci assicuriamo che tutto
                      funzioni al meglio nel tempo, garantendo un supporto
                      continuo per mantenere la tua soluzione sempre al top.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-12 row m-0 mt-5 py-0 justify-content-center">
              <video
                className={
                  isMobile
                    ? "path-video p-0 mx-auto"
                    : "path-video p-0 max-width-card-section mx-auto"
                }
                autoPlay
                loop
                muted
              >
                <source src={creativeVideo} type="video/mp4" />
              </video>
              <div className="col max-width-card-tech p-0 m-0">
                <div
                  className={
                    "col-12 m-0 px-2 py-3 panel row justify-content-end animate__animated animate__zoomIn white shadow"
                  }
                >
                  <div className="col-12 m-0 row p-0">
                    <div className="icon-tech-analysis-little"></div>
                    <div className="col m-0 mt-1 p-0 px-1">
                      <span className="title-card-home Poppins">Analisi</span>
                    </div>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home PoppinsLight">
                      Studiamo attentamente le tue necessità e il mercato per
                      trovare la strada migliore per realizzare il tuo progetto
                      con successo.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 row m-0 mt-4 py-0 justify-content-center">
              <div className="col max-width-card-tech p-0 m-0">
                <div
                  className={
                    "col-12 m-0 px-2 py-3 panel row justify-content-end animate__animated animate__zoomIn white shadow"
                  }
                >
                  <div className="col-12 m-0 row p-0">
                    <div className="icon-tech-build-little"></div>
                    <div className="col m-0 mt-1 p-0 px-1">
                      <span className="title-card-home Poppins">Sviluppo</span>
                    </div>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home PoppinsLight">
                      Ecco dove trasformiamo le idee in azione, dando vita al
                      tuo progetto passo dopo passo.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 row m-0 mt-4 py-0 justify-content-center">
              <div className="col max-width-card-tech p-0 m-0">
                <div
                  className={
                    "col-12 m-0 px-2 py-3 panel row justify-content-end animate__animated animate__zoomIn white shadow"
                  }
                >
                  <div className="col-12 m-0 row p-0">
                    <div className="icon-tech-maintenance-little"></div>
                    <div className="col m-0 p-0 px-1">
                      <span className="title-card-home Poppins">
                        Mantenimento
                      </span>
                    </div>
                  </div>
                  <div className="col-12 m-0">
                    <span className="description-card-home PoppinsLight">
                      Una volta completato il progetto, ci assicuriamo che tutto
                      funzioni al meglio nel tempo, garantendo un supporto
                      continuo per mantenere la tua soluzione sempre al top.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="col-12 section row">
          <div className="col-12 m-0 p-0 mt-4 ">
            <div
              className={
                isMobile
                  ? "col-12 m-0 mx-auto page-card-padding row"
                  : "col-12 max-width-card-section m-0 mx-auto page-card-padding row "
              }
            >
              {/* <div className="col-12 row section py-0 text-align-center justify-content-center">
                <div className="m-0 tag-card-div p-0 normal">
                  <div className="tag-card px-3 p-0 tag-item purple">
                    <span className="tag-label Poppins color-white center-tag-span">
                      Percorso creativo
                    </span>
                  </div>
                </div>
              </div> */}
              <div className="purple-border purple">
                <div className="col-12 m-0 p-0">
                  <span className="tag-label PoppinsLight color-white">
                    E quindi cosa stai aspettando?{" "}
                  </span>
                </div>
                <div className="col-12 m-0 p-0">
                  <span className="tag-label PoppinsLight oblique color-white">
                    Scrivici
                  </span>
                </div>
              </div>
            </div>
          </div>
          {isLittleView || isMobile ? (
            <>
              <div
                className={
                  isMobile
                    ? "col-12 m-0 mt-2 p-0 row mx-auto"
                    : "col-12 m-0 mt-2 p-0 row max-width-card-section mx-auto"
                }
              >
                <div className="m-0 page-card-padding-both email-button-div">
                  <div
                    className="col-12 contact-button relative pointer border-light-grey"
                    onMouseEnter={() => setButtonHover("mail")}
                    onMouseLeave={() => {
                      setButtonHover("");
                      setInfoCopied("");
                    }}
                    onClick={() => copyInfoToClipboard("mail")}
                  >
                    <span
                      className={
                        buttonHover === "mail"
                          ? "footer-span Poppins email-transition-in"
                          : "footer-span Poppins email-transition-in move-in"
                      }
                    >
                      sold.online.official@gmail.com
                    </span>
                    <span
                      className={
                        buttonHover === "mail"
                          ? "footer-span Poppins email-transition-out"
                          : "footer-span Poppins email-transition-out move-out"
                      }
                    >
                      {isInfoCopied === "mail" ? "Copied :)" : "Copy it"}
                    </span>
                  </div>
                </div>
                {/* <div className="m-0 page-card-padding-both phone-button-div">
                  <div
                    className="col-12 contact-button relative pointer border-green"
                    onMouseEnter={() => setButtonHover("phone")}
                    onMouseLeave={() => {
                      setButtonHover("");
                      setInfoCopied("");
                    }}
                    onClick={() => copyInfoToClipboard("phone")}
                  >
                    <span
                      className={
                        buttonHover === "phone"
                          ? "footer-span Poppins email-transition-in"
                          : "footer-span Poppins email-transition-in move-in"
                      }
                    >
                      +39 3924162864
                    </span>
                    <span
                      className={
                        buttonHover === "phone"
                          ? "footer-span Poppins email-transition-out"
                          : "footer-span Poppins email-transition-out move-out"
                      }
                    >
                      {isInfoCopied === "phone" ? "Copied :)" : "Copy it"}
                    </span>
                  </div>
                </div> */}
              </div>
              {/* <div
                className={
                  isMobile
                    ? "col-12 m-0 mt-2 p-0 row mx-auto"
                    : "col-12 m-0 mt-2 p-0 row max-width-card-section mx-auto"
                }
              >
                <div className="m-0 page-card-padding-both insta-button-div">
                  <div
                    className="col-12 contact-button relative pointer border-yellow"
                    onMouseEnter={() => setButtonHover("insta")}
                    onMouseLeave={() => {
                      setButtonHover("");
                      setInfoCopied("");
                    }}
                    onClick={redirectInsta}
                  >
                    <span
                      className={
                        buttonHover === "insta"
                          ? "footer-span Poppins email-transition-in"
                          : "footer-span Poppins email-transition-in move-in"
                      }
                    >
                      @sold.official
                    </span>
                    <span
                      className={
                        buttonHover === "insta"
                          ? "footer-span Poppins email-transition-out"
                          : "footer-span Poppins email-transition-out move-out"
                      }
                    >
                      Go to
                    </span>
                  </div>
                </div>
              </div> */}
            </>
          ) : (
            <div
              className={
                isMobile
                  ? "col-12 m-0 mt-2 p-0 row mx-auto"
                  : "col-12 m-0 mt-2 p-0 row max-width-card-section mx-auto"
              }
            >
              <div className="m-0 page-card-padding-both email-button-div">
                <div
                  className="col-12 contact-button relative pointer border-light-grey"
                  onMouseEnter={() => setButtonHover("mail")}
                  onMouseLeave={() => {
                    setButtonHover("");
                    setInfoCopied("");
                  }}
                  onClick={() => copyInfoToClipboard("mail")}
                >
                  <span
                    className={
                      buttonHover === "mail"
                        ? "footer-span Poppins email-transition-in"
                        : "footer-span Poppins email-transition-in move-in"
                    }
                  >
                    sold.online.official@gmail.com
                  </span>
                  <span
                    className={
                      buttonHover === "mail"
                        ? "footer-span Poppins email-transition-out"
                        : "footer-span Poppins email-transition-out move-out"
                    }
                  >
                    {isInfoCopied === "mail" ? "Copiato :)" : "Copia"}
                  </span>
                </div>
              </div>
              {/* <div className="m-0 page-card-padding-both phone-button-div">
                <div
                  className="col-12 contact-button relative pointer border-green"
                  onMouseEnter={() => setButtonHover("phone")}
                  onMouseLeave={() => {
                    setButtonHover("");
                    setInfoCopied("");
                  }}
                  onClick={() => copyInfoToClipboard("phone")}
                >
                  <span
                    className={
                      buttonHover === "phone"
                        ? "footer-span Poppins email-transition-in"
                        : "footer-span Poppins email-transition-in move-in"
                    }
                  >
                    +39 3924162864
                  </span>
                  <span
                    className={
                      buttonHover === "phone"
                        ? "footer-span Poppins email-transition-out"
                        : "footer-span Poppins email-transition-out move-out"
                    }
                  >
                    {isInfoCopied === "phone" ? "Copiato :)" : "Copia"}
                  </span>
                </div>
              </div> */}
              {/* {isLittleView || (isMobile && <br></br>)}
              <div className="m-0 page-card-padding-both insta-button-div">
                <div
                  className="col-12 contact-button relative pointer border-yellow"
                  onMouseEnter={() => setButtonHover("insta")}
                  onMouseLeave={() => {
                    setButtonHover("");
                    setInfoCopied("");
                  }}
                  onClick={redirectInsta}
                >
                  <span
                    className={
                      buttonHover === "insta"
                        ? "footer-span Poppins email-transition-in"
                        : "footer-span Poppins email-transition-in move-in"
                    }
                  >
                    @sold.official
                  </span>
                  <span
                    className={
                      buttonHover === "insta"
                        ? "footer-span Poppins email-transition-out"
                        : "footer-span Poppins email-transition-out move-out"
                    }
                  >
                    Vai su ig
                  </span>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ITServicesPage;
